import React from 'react';
import './App.css';
import ViewPersons from './components/viewPersons';
import Person from './components/person';
import Button from '@mui/material/Button';
// import legacyCalc from './components/legacyCalc';
import {IPerson, IRelative} from './models/person'

declare const window: any;

function App() {
  const [persons, setPersons] = React.useState<IPerson[]>([]);
  const [personToEdit, setPersonToEdit] = React.useState<IPerson | null>(null);
  const [personsCount, setPersonsCount] = React.useState(0);
  const [revision, setRevision] = React.useState(0)

  const addPerson = (person: IPerson) => {
    persons.push(person)
    setPersonsCount(personsCount + 1)
    setPersons(persons);
    setPersonToEdit(null)
    setRevision(revision + 1)
  }

  const editPerson = (person: IPerson) => {
    const foundIdx = persons.findIndex((p) => p.id === person.id)
    if (foundIdx > -1) {
      persons[foundIdx].id = person.id
      persons[foundIdx].name = person.name
      persons[foundIdx].relatives = person.relatives
      persons[foundIdx].percent = person.percent
      persons[foundIdx].onLife = person.onLife
      setPersons(persons)
      setPersonToEdit(null)
      setRevision(revision + 1)
    }
  }
  const deletePerson = (id: number) => {
    const foundIdx = persons.findIndex((p) => p.id === id)
    if (foundIdx > -1) { // only splice array when item is found
      persons.splice(foundIdx, 1); // 2nd parameter means remove one item only
      setPersons(persons)
      setRevision(revision + 1)
    }
  }

  const handleEditPerson = (id: number) => {
    const found = persons.find(p => p.id === id)
    if (found) {
      setPersonToEdit(found)
    }
  }

  const addRelative = (id: number, obj: IRelative) => {
    const foundPerson = persons.find(p => p.id === id);
    if (foundPerson) {
      foundPerson.relatives.push(obj);
    }
  }

  const calculate = () => {
    window.persons[0].setLegacy(1)
    setRevision(revision + 1)
  }

  React.useEffect(() => {
    
    window.persons = persons
  }, [persons])

  return (
    <div className="App" key={revision}>
      <ViewPersons rows={persons} handleEditPerson={handleEditPerson} delete={deletePerson}/>
      <Person addRelative={addRelative} persons={persons} submit={addPerson} submitEdit={editPerson} data={personToEdit}/>
      <Button key={'addPersonBtn'} variant="contained"  sx={{height: '50px'}} disabled={!(window.persons && window.persons.length > 0)} onClick={calculate}>Calculate</Button>

    </div>
  );
}

export default App;
