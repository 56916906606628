import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ViewRelations from "./viewRelations";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { IPerson, IRelative } from "../models/person";
import { Person } from "./legacyCalc";

type Props = {
  data?: IPerson | null;
  persons: IPerson[];
  submit: (person: IPerson) => void;
  submitEdit: (person: IPerson) => void;
  addRelative: (id: number, object: IRelative) => void;
};

const Relativeness = {
  Hausband: "1",
  Parent: "2",
  Child: "3",
  Brother: "4",
};

function PersonComponent(props: Props) {
  const [id, setId] = React.useState<number>(props.persons.length + 1 ?? 0);
  const [name, setName] = React.useState<string>("");
  const [relatives, setRelatives] = React.useState<IRelative[]>([]);
  const [percent, setPercent] = React.useState<number>(0);
  const [onLife, setOnLife] = React.useState<boolean>(true);
  const [revision, setRevision] = React.useState<number>(0);
  const [onEdit, setOnEdit] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.data) {
      setId(props.data.id);
      setName(props.data.name);
      setRelatives(props.data.relatives);
      setPercent(props.data.percent);
      setOnLife(props.data.onLife);
      setOnEdit(true);
    }
  }, [props.data]);

  const setCorespondingRelative = (newRelative: IRelative) => {
    
    const person = props.persons.find((p: IPerson) => p.id === newRelative.id);
    if (person) {
      const found = person.relatives.findIndex(
        (relative) => relative.id === id
      );
      if (found < 0) {
        props.addRelative(newRelative.id, {
          id,
          relativeness: getOppositeRelationship(newRelative.relativeness),
        });
      }
    }
  }

  const addRelative = (newRelative: IRelative) => {
    relatives.push(newRelative);
    setRelatives(relatives);
    setRevision(revision + 1);

    setCorespondingRelative(newRelative)
  };

  const editRelative = (index: number, relative: IRelative) => {
    const found = relatives[index]
    if (found) {
      relatives[index].id = relative.id;
      relatives[index].relativeness = relative.relativeness;
      setRelatives(relatives);
      setRevision(revision + 1);

      setCorespondingRelative(relative)
    } else {
        addRelative(relative)
    }
  };
  const deleteRelative = (index: number) => {
    const found = relatives[index];
    if (found) {
      // only splice array when item is found
      relatives.splice(index, 1); // 2nd parameter means remove one item only
      setRelatives(relatives);
      setRevision(revision + 1);
    }
  };

  const addPerson = () => {
    const newPerson: IPerson = new Person(id, name, relatives, percent, onLife);

    props.submit(newPerson);
    init();
  };

  const editPerson = () => {
    const dataClone = Object.assign({}, props.data);
    dataClone.id = id;
    dataClone.name = name;
    dataClone.relatives = relatives;
    dataClone.percent = percent;
    dataClone.onLife = onLife;
    props.submitEdit(dataClone);
    init();
  };

  const submitPerson = () => {
    const idx = props.persons.findIndex((p) => p.id === id);
    if (idx > -1) {
      editPerson();
    } else {
      addPerson();
    }
  };

  const getOppositeRelationship = (relation: string) => {
    switch (relation) {
      case Relativeness.Hausband:
        return Relativeness.Hausband;
      case Relativeness.Parent:
        return Relativeness.Child;
      case Relativeness.Child:
        return Relativeness.Parent;
      case Relativeness.Brother:
      default:
        return Relativeness.Brother;
    }
  };

  const init = () => {
    setId(props.persons.length + 1);
    setName("");
    setRelatives([]);
    setPercent(0);
    setOnLife(true);
    setOnEdit(false);
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
      key={revision}
    >
      <Stack direction="row" spacing={2}>
        <TextField
          id="outlined-basic"
          label="Enter Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <Stack direction="column" spacing={2}>
          <ViewRelations
            rows={relatives}
            persons={props.persons}
            handleEditRelation={() => {}}
            delete={() => {}}
            addRelative={addRelative}
            editRelative={editRelative}
            deleteRelative={deleteRelative}
            editable={true}
          />
        </Stack>

        <Box>
          {"Legacy Percent"}
          <TextField
            inputProps={{ type: "number" }}
            id="outlined-basic"
            label="Enter Percentage"
            variant="outlined"
            value={percent}
            onChange={(e) => setPercent(parseInt(e.target.value))}
          />
        </Box>

        <Box>
          {"Is alive?"}
          <Checkbox
            checked={onLife}
            onChange={() => setOnLife(!onLife)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>

        <Button
          key={"addPersonBtn"}
          variant="contained"
          onClick={submitPerson}
          sx={{ height: "50px" }}
        >
          {onEdit ? "Update Person" : "Add Person"}
        </Button>

        <Button
          key={"cancelBtn"}
          variant="outlined"
          onClick={init}
          sx={{ height: "50px" }}
        >
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}
export default PersonComponent;
