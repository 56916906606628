const Relativeness = {
    Hausband: '1',
    Parent: '2',
    Child: '3',
    Brother: '4'
};

const persons = window.persons;

export class Person {
    constructor(id, name, relatives, percent, onLife) {
        this.id = id;
        this.name = name;
        this.relatives = relatives;
        this.percent = percent;
        this.onLife = onLife;
    }

    getPersonById(id) {
        return window.persons.find(p => p.id == id);
    }

    hasHausband() {
        return this.relatives.filter(r => r.relativeness == Relativeness.Hausband).length > 0;
    }

    getHausband() {
        return this.relatives.filter(r => r.relativeness == Relativeness.Hausband).map(rel => this.getPersonById(rel.id))[0];
    }

    hasChilds() {
        return this.getChilds().length > 0;
    }

    getChilds() {
        return this.relatives.filter(r => r.relativeness == Relativeness.Child).map(rel => this.getPersonById(rel.id)).filter(item => item);
    }

    hasBrothers() {
        return this.getBrothers().length > 0;
    }

    getBrothers() {
        return this.relatives.filter(r => r.relativeness == Relativeness.Brother).map(rel => this.getPersonById(rel.id)).filter(item => item);
    }

    hasParents() {
        return this.getParents().length > 0;
    }

    getParents() {
        return this.relatives.filter(r => r.relativeness == Relativeness.Parent).map(rel => this.getPersonById(rel.id)).filter(item => item);
    }

    hasRelatives() {
        return this.relatives.length > 0;
    }

    isAlive() {
        return this.onLife;
    }

    setLegacy(percent) {
        if (this.isAlive()) {
            this.percent = percent;
        } else {
            this.percent = 0;
            this.shareLegacy(percent);
        }
    }

    shareLegacy(percent) {
        if (this.hasRelatives()) {
            if (this.hasHausband()) {
                if (this.hasChilds()) {
                    this.getHausband().setLegacy(percent/4);
                    const leftOver = percent*3/4;
                    this.getChilds().forEach(child => child.setLegacy(leftOver/this.getChilds().length));
                } else {
                    this.getHausband().setLegacy(percent/2);
                    const leftOver = percent/2;
                    this.getParents().forEach(parent => parent.setLegacy(leftOver/this.getParents().length));
                }
            } else if (this.hasChilds()) {
                this.getChilds().forEach(child => child.setLegacy(percent/this.getChilds().length));
            } else if (this.hasBrothers()) {
                this.getBrothers().forEach(parent => parent.setLegacy(percent/this.getBrothers().length));
            } else if (this.hasParents()) {
                this.getParents().forEach(parent => parent.setLegacy(percent/this.getParents().length));
            }
        }
    }
}