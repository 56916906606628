import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { IPerson, IRelative } from "../models/person";
import TableCell from "@mui/material/TableCell";

const Relativeness = {
  Hausband: "1",
  Parent: "2",
  Child: "3",
  Brother: "4",
};

type Props = {
  persons: IPerson[];
  submit: (relative: IRelative) => void;
  submitEdit: (index: number, relative: IRelative) => void
  data?: IRelative;
  editable?: boolean;
  index: number 
};

export default function RelationComponent(props: Props) {
  const [id, setId] = React.useState<number>(-1);
  const [relativeness, setRelativeness] = React.useState<string>("0");

  React.useEffect(() => {
    if (props.data) {
      setId(props.data.id);
      setRelativeness(props.data.relativeness);
    }
  }, [props.data]);


  const addRelation = () => {
    const newRelation: IRelative = {
      id,
      relativeness,
    };
    props.submit(newRelation);
    init();
  };

  const editRelation = (newRel: string) => {
    if (props.index) {
        const updatedRelation: IRelative = {
            id,
            relativeness: newRel,
          };
          props.submitEdit(props.index, updatedRelation);
    }
  };

  const handleSetRelativeness = (value :string) => {
    setRelativeness(value)
    editRelation(value)
  }

  const init = () => {
    setId(-1);
    setRelativeness("0");
  };

  return (
    <>
      <TableCell align="right" sx={{width: '50%'}}>
        <FormControl size="small" sx={{width: 100}}>
            {/* <InputLabel id="demo-simple-select-label">Relation With</InputLabel> */}
            <Select
            key={"select-relative"}
            id="select-relative"
            value={id}
            label="Relations with"
            onChange={(e) => setId(parseInt(e.target.value.toString()))}
            disabled={props.persons.length === 0 || !props.editable}
            >
            <MenuItem value={"-1"}>Select Relative</MenuItem>
            {props.persons &&
                props.persons.map((person) => (
                <MenuItem value={person.id}>{person.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
      </TableCell>
      <TableCell align="right" sx={{width: '50%'}}>
        <FormControl size="small" sx={{width: 100}}>
            <Select
            key={"select-relativeness"}
            id="select-relativeness"
            value={relativeness}
            label="Relations"
            onChange={(e) => handleSetRelativeness(e.target.value)}
            disabled={id === -1 || !props.editable}
            >
            <MenuItem value={"0"}>Select Relativeness</MenuItem>
            <MenuItem value={Relativeness.Hausband}>Hausband</MenuItem>
            <MenuItem value={Relativeness.Parent}>Parent</MenuItem>
            <MenuItem value={Relativeness.Child}>Child</MenuItem>
            <MenuItem value={Relativeness.Brother}>Brother</MenuItem>
            </Select>
        </FormControl>
      </TableCell>
      {/* <TableCell align="right">
        {props.editable ? (
          <Button
            variant="contained"
            onClick={addRelation}
            disabled={id === -1 || relativeness === "0"}
          >
            Add Relation
          </Button>
        ) : null}
      </TableCell> */}
       {/*  </Box> */}
    </>
  );
}
