import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IPerson, IRelative } from "../models/person";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Relation from "./relation";

const Relativeness = {
  Hausband: "1",
  Parent: "2",
  Child: "3",
  Brother: "4",
};

const getRelativenessName = (rel: string) => {
  switch (rel) {
    case Relativeness.Hausband:
      return "Hausband";
    case Relativeness.Parent:
      return "Parent";
    case Relativeness.Child:
      return "Child";
    default:
    case Relativeness.Brother:
      return "Brother";
  }
};

type Props = {
  rows: IRelative[];
  persons: IPerson[];
  editable: boolean;
  handleEditRelation: (id: number) => void;
  delete: (id: number) => void;
  addRelative: (relative: IRelative) => void;
  editRelative: (index: number, relative: IRelative) => void;
  deleteRelative: (id: number) => void;
};

const defaultProps = {
  rows: [],
  persons: [],
  editable: false,
  handleEditRelation: () => {},
  delete: () => {},
  addRelative: () => {},
  editRelative: () => {},
  deleteRelative: () => {},
};

function ViewRelations(props: Props = defaultProps) {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(props.editable)

  return (
    <TableContainer component={Paper} sx={{ maxWidth: 300 }} >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Id</TableCell>
            <TableCell align="right">Relativeness</TableCell>
            <TableCell>
              <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isExpanded && props.rows.map((row, idx) => (
            <TableRow
              key={`rltn-${idx}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <Relation
                index={idx}
                persons={props.persons}
                submit={props.addRelative}
                submitEdit={props.editRelative}
                data={row}
                editable={props.editable}
              />
            </TableRow>
          ))}

          <TableRow>
            {props.editable ? (
              <Relation
                index={props.rows.length + 1}
                persons={props.persons}
                submit={props.addRelative}
                submitEdit={props.editRelative}
                editable={props.editable}
              />
            ) : null}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ViewRelations;
