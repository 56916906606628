import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ViewRelations from "./viewRelations";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IPerson } from "../models/person";

type Props = {
  rows: IPerson[];
  handleEditPerson: (id: number) => void;
  delete: (id: number) => void
};

export default function BasicTable(props: Props) {
  if (props.rows.length === 0) return null;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Relatives</TableCell>
            <TableCell align="right">Percent&nbsp;(%)</TableCell>
            <TableCell align="right">On Life?</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="right">{row.id}</TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              {/* <TableCell align="right">{row.relatives.map(relative => `${relative.id},`)}</TableCell> */}
              <TableCell align="right">
                <ViewRelations rows={row.relatives} persons={props.rows} editable={false} handleEditRelation={() => {}} delete={() => {}} addRelative={() => {}} editRelative={() => {}} deleteRelative={() => {}}/>
              </TableCell>
              <TableCell align="right">{row.percent}</TableCell>
              <TableCell align="right">{row.onLife ? "Yes" : "No"}</TableCell>
              <TableCell align="right">
                <Button variant="outlined" startIcon={<EditIcon />} onClick={() => props.handleEditPerson(row.id)}>
                  Edit
                </Button>
                <Button variant="outlined" startIcon={<DeleteIcon />} onClick={() => props.delete(row.id)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
